document.addEventListener('DOMContentLoaded', () => {
  let visitCodeInput = document.getElementById('forgot_visit_submission_visit_code')
  if (visitCodeInput) {
    visitCodeInput.addEventListener('blur', (e) => {
      let formattedCode = e.target.value.replace(/\D/g, '')
      let splitCode = formattedCode.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})(\d*)/)

      if (splitCode[2]) {
        formattedCode = splitCode[1] + ' ' + splitCode[2]
      }

      if (splitCode[3]) {
        formattedCode += ' ' + splitCode[3]
      }

      if (splitCode[4]) {
        formattedCode += ' ' + splitCode[4]
      }

      if (splitCode[5]) {
        formattedCode += ' ' + splitCode[5]
      }

      if (splitCode[6]) {
        formattedCode += ' ' + splitCode[6]
      }

      if (splitCode[7]) {
        formattedCode += ' ' + splitCode[7]
      }

      e.target.value = formattedCode
    })
  }
})
