import "../application/styles";
import "../mobile_app/notifications";
import "../application/navbar";
import "../application/visit_code";

var Honeybadger = require("honeybadger-js/dist/honeybadger");

Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.RAILS_ENV || "development",
  revision: process.env.GIT_COMMIT || "master",
  debug: true,
});
