document.addEventListener('DOMContentLoaded', () => {
  const notifications = Array.prototype.slice.call(document.querySelectorAll('.notification .delete'), 0)

  if (notifications.length > 0) {
    notifications.forEach((deleteEl) => {
      let notificationEl = deleteEl.parentNode
      deleteEl.addEventListener('click', () => {
        notificationEl.parentNode.removeChild(notificationEl)
      })
    })
  }
})
